import React from "react";
import { Link } from "react-router-dom";
import TitleBanner from "../components/TitleBanner";
import Seo from "../components/Seo";
import FBPost from "../components/FBPost"


const RecentMoves = () => {
    const metaData = [
        {
            name: "keywords",
            content: "services, removals, home, office, packing, unpacking, assembly,Dismantling, reassembly, furniture, loading, un-loading, cleaning, man and van, van",
        },
    ];
    return (
        <div>
            <Seo
                title="Recent Moves page for SweetMoves, Home and Office removals UK wide including local man and van services based in Cardiff, Wales."
                description="The Recent Moves page for SweetMoves which shows some information on some of our services."
                meta={metaData} />
            <TitleBanner text="Recent Moves" />
            <div className="">
                <div className="row">
                     <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/1078827073960462" />
                    </div>
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/1068293158347187" />
                    </div>                   
                {/* </div>
                <div className="row"> */}
                <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/1072994857877017" />
                    </div>
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/1078236484019521" />
                    </div>
                {/* </div>
                <div className="row"> */}
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/1067777391732097" />
                    </div>
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/1057738442735992" />
                    </div>
                {/* </div>

                <div className="row"> */}
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/929775835532254" />
                    </div>
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/909366417573196" />
                    </div>
                {/* </div>
                <div className="row"> */}
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/909371004239404" />
                    </div>
                    <div className="col-md-6">
                        <FBPost url="https://www.facebook.com/sweetmoves22/posts/911535440689627" />
                    </div>

                </div>

            </div>




        </div>
    );
}

export default RecentMoves;