import React from "react";
import { FacebookEmbed } from "react-social-media-embed";
// import  FacebookPost from 'react-social-media-embed';

function CardDetails(props) {
    return (
        // <div className="fb-post-container">
        //     {/* <iframe src={props.url} title="fb-posts" height={props.height} width={props.width} className="fb-post" ></iframe> */}
        // </div>

        <div className="fb-post-container p-1">
        {/* The url for the fb post can be found by clicking embed post -> advanced -> generate code -> link where cite="?" */}
            <FacebookEmbed url={props.url} width="100%"/>
            {/* <FacebookEmbed url={props.url} width="auto"/> */}
        </div>
        
    );
}

export default CardDetails;