import React from "react";

import TitleBanner from "../components/TitleBanner"
import FBPost from "../components/FBPost"
import Seo from "../components/Seo";

const Clients = () => {
  const metaData = [
    {
      name: "keywords",
      content: "reviews,services, removals, home, office, packing, unpacking, assembly,Dismantling, reassembly, furniture, loading, un-loading, cleaning, man and van, van",
    },
  ];
  return (

    <div className="">
      <Seo
        title="Reviews for SweetMoves, Home and Office removals UK wide including local man and van services based in Cardiff, Wales."
        description="The Reviews page for SweetMoves which lists and explains all services provided."
        meta={metaData} />
      <TitleBanner text="Reviews" />
      <div className="fb-comments row">
        <div className="col-12">
          <a className="btn btn-about" href="https://www.facebook.com/sweetmoves22/reviews">See All Facebook Reviews here</a>
          <a className="btn btn-about" href="https://www.google.com/search?q=sweet+moves&oq=sweet+moves&gs_lcrp=EgZjaHJvbWUyDggAEEUYJxg5GIAEGIoFMgwIARAjGCcYgAQYigUyDQgCEC4YrwEYxwEYgAQyBwgDEAAYgAQyDQgEEC4YrwEYxwEYgAQyCQgFEC4YChiABDIGCAYQRRg9MgYIBxBFGDzSAQgzODE1ajBqNKgCALACAA&sourceid=chrome&ie=UTF-8#">See All Google Reviews here</a>
        </div>

        <div className="col-lg-6">
          <FBPost url="https://www.facebook.com/kathryn.baldwin.94/posts/10159906807907621" />
          <FBPost url="https://www.facebook.com/megan.roderick.904/posts/2353528608179444" />
          <FBPost url="https://www.facebook.com/christine.evans.5220665/posts/2489906184551977" />
          <FBPost url="https://www.facebook.com/chloe.venables.336/posts/2956051811201117" />
          <FBPost url="https://www.facebook.com/marilyn.fisher.520562/posts/1839109186598944" />
          <FBPost url="https://www.facebook.com/angela.evans.1806/posts/10161411563563158" />
          <FBPost url="https://www.facebook.com/vernasamantha/posts/10168249239710416" />
          <FBPost url="https://www.facebook.com/keryn.stapleton/posts/10160609775243726" />
          <FBPost url="https://www.facebook.com/ana.godwin.3/posts/10159600759565732" />
        </div>

        <div className="col-lg-6">
          <FBPost url="https://www.facebook.com/jordan.sakkas/posts/10224328983358899" />
          <FBPost url="https://www.facebook.com/rhian.jones.5623/posts/10159357925846572" />
          <FBPost url="https://www.facebook.com/claire.gittins/posts/10162210927712316" />
          <FBPost url="https://www.facebook.com/geniewkcss/posts/10222724386116012" />


          <FBPost url="https://www.facebook.com/louise.hewlett/posts/10159027203516205" />
          <FBPost url="https://www.facebook.com/permalink.php?story_fbid=276575628319060&amp;id=100078998740224" />

          <FBPost url="https://www.facebook.com/emma.powderhill/posts/10161120699079455" />


        </div>
      </div>
    </div>
  );
}

export default Clients;